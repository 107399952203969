















































































import {BenefitInterface} from '@/shared/interfaces/store/homepage/benefit.interface';
import {HomepageStore} from '@/store/modules/homepage';
import {UiConfigStore} from '@/store/modules/ui-config';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomeBenefits extends Vue {

  selected = 0;

  get logo(): string {
    return UiConfigStore.logoContrast;
  }

  get benefits(): BenefitInterface[] {
    return HomepageStore.benefits;
  }

  get title(): string {
    return HomepageStore.benefitsTitle;
  }

}
